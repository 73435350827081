import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"

import Seo from "../components/seo"
import { Article, ArticleDate, ArticleHeader, ArticleSection } from "../styledComponents/article"

const PrivacyPolicy = ({ data, location, createCookie }) => {
  const pageTitle = data.wpPage.title
  const pageContent = data.wpPage.content
    return (
      <Layout displayNavBorder location={location}>
      <Seo 
      title={data.wpPage.title} 
      description="The terms and conditions for the website of ticg.co.uk"
      url="/terms-and-conditions"
      />
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader>
          <h1 itemProp="headline">{pageTitle}</h1>
          <ArticleDate dateTime={data.wpPage.dateGmt}>{data.wpPage.date}</ArticleDate>
        </ArticleHeader>

        {!!pageContent && (
          <ArticleSection itemProp="articleBody">
            {parse(pageContent)}
          </ArticleSection>
        )}
      </Article>
    </Layout>
    )
}


export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "privacy-policy" }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      dateGmt
    }
  }
`